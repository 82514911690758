import { useTranslation } from 'react-i18next'

import { IllustrationCards } from './IllustrationCards'

import type { IllustrationCardProps } from './IllustrationCard'

export const BenefitCards = () => {
  const { t } = useTranslation()

  const bannerHeadLineText = t('benefitCards.headline')

  const cards: IllustrationCardProps[] = [
    {
      name: 'roastery-new',
      headlineText: t('benefitCards.card01.headline'),
      text: t('benefitCards.card01.text'),
    },
    {
      name: 'subscription-new',
      headlineText: t('benefitCards.card02.headline'),
      text: t('benefitCards.card02.text'),
    },
    {
      name: 'preparation-new',
      headlineText: t('benefitCards.card03.headline'),
      text: t('benefitCards.card03.text'),
    },
  ]

  return <IllustrationCards cards={cards} bannerHeadLineText={bannerHeadLineText} />
}
