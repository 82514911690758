import clsx from 'clsx'

import { Button } from 'src/components/Button/Button'
import { Headline } from 'src/components/Headline/Headline'
import { Image } from 'src/components/Image/Image'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'
import { IMAGE_TEXT_CONTAINER } from 'src/selectors'

import styles from './ImageTextContainer.module.scss'

import type { ButtonProps } from 'src/components/Button/Button'

interface ImageTextContainerProps {
  title: string
  intro: string
  buttonText: string
  buttonUrl: string
  imageId: string
  imagePosition?: 'left' | 'right'
  buttonVariant?: ButtonProps['variant']
}

export const ImageTextContainer = ({
  title,
  intro,
  buttonText,
  buttonUrl,
  imageId,
  imagePosition = 'right',
  buttonVariant = 'primary',
}: ImageTextContainerProps) => (
  <section
    className={clsx(styles.container, imagePosition === 'right' && styles.hasImageOnRightSide)}
    data-cy={IMAGE_TEXT_CONTAINER}
  >
    <div className={styles.contentColumn}>
      <Headline variant="h4" className="h1">
        {title}
      </Headline>
      <Text variant="intro" role="heading" aria-level={4}>
        {intro}
      </Text>
      <Button variant={buttonVariant} href={buttonUrl} inverted={buttonVariant !== 'primary'}>
        {buttonText}
      </Button>
    </div>

    <div className={styles.imageColumn}>
      <Image
        identifier={imageId}
        alt={title}
        srcSet={[350, 700, 1400]}
        sizes={`(min-width: ${config.breakpoints.md}px) 50vw, 100vw`}
        width="748"
        height="608"
        className={styles.image}
        responsive
        lazy
      />
    </div>
  </section>
)
