import Head from 'next/head'

import { config } from 'src/config'
import { useLocale } from 'src/contexts/LocaleContext'
import { Locale } from 'src/enum/Locale'

interface HeadCanonicalsProps {
  de: string
  en?: string
}

export const HeadCanonicals = ({ de, en }: HeadCanonicalsProps) => {
  const { locale } = useLocale()

  return (
    <Head>
      {locale === Locale.deDE && <link rel="canonical" href={`${config.host}${de}`} />}
      {locale === Locale.enGB && <link rel="canonical" href={`${config.host}${en}`} />}
      <link rel="alternate" href={`${config.host}${de}`} hrefLang="de" />
      {en && <link rel="alternate" href={`${config.host}${en}`} hrefLang="en" />}
      <link rel="alternate" href={`${config.host}${de}`} hrefLang="x-default" />
    </Head>
  )
}
