import { Divider } from 'src/components/Divider/Divider'
import { Headline } from 'src/components/Headline/Headline'
import { Text } from 'src/components/Text/Text'

import styles from './BrandValues.module.scss'

interface BrandValueCardProps {
  title: string
  text: string
}

export const BrandValueCard = ({ title, text }: BrandValueCardProps) => (
  <div className={styles.card}>
    <Divider bold />
    <Headline variant="h3" className={styles.title}>
      {title}
    </Headline>
    <Text variant="p" className={styles.text} dangerouslySetInnerHTML={{ __html: text }} suppressHydrationWarning />
  </div>
)
