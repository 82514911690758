import { useTranslation } from 'react-i18next'

import { Container } from 'src/components/Container/Container'
import { BRAND_VALUES } from 'src/selectors'

import { BrandValueCard } from './BrandValueCard'
import styles from './BrandValues.module.scss'

export const BrandValues = () => {
  const { t } = useTranslation()
  const brandData = [
    {
      title: t('brandValues.card01.title'),
      text: t('brandValues.card01.text'),
    },
    {
      title: t('brandValues.card02.title'),
      text: t('brandValues.card02.text'),
    },
    {
      title: t('brandValues.card03.title'),
      text: t('brandValues.card03.text'),
    },
    {
      title: t('brandValues.card04.title'),
      text: t('brandValues.card04.text'),
    },
  ]
  return (
    <div className={styles.brand} data-cy={BRAND_VALUES}>
      <Container className={styles.container}>
        {brandData.map((item, index) => (
          <BrandValueCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={item.title}
            text={item.text}
          />
        ))}
      </Container>
    </div>
  )
}
