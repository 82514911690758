import { useTranslation } from 'react-i18next'

import { PROMO_CARDS } from 'src/selectors'

import { PromoCard } from './PromoCard'
import styles from './PromoCards.module.scss'

export const PromoCards = () => {
  const { t } = useTranslation()

  const promoData = [
    {
      image: t('promoCards.card01.imageId'),
      title: t('promoCards.card01.title'),
      text: t('promoCards.card01.text'),
      link: t('promoCards.card01.link'),
    },
    {
      image: t('promoCards.card02.imageId'),
      title: t('promoCards.card02.title'),
      text: t('promoCards.card02.text'),
      link: t('promoCards.card02.link'),
    },
    {
      image: t('promoCards.card03.imageId'),
      title: t('promoCards.card03.title'),
      text: t('promoCards.card03.text'),
      link: t('promoCards.card03.link'),
    },
    {
      image: t('promoCards.card04.imageId'),
      title: t('promoCards.card04.title'),
      text: t('promoCards.card04.text'),
      link: t('promoCards.card04.link'),
    },
  ]

  return (
    <div className={styles.grid} data-cy={PROMO_CARDS}>
      {promoData.map((item, index) => (
        <PromoCard
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          imageId={item.image}
          title={item.title}
          text={item.text}
          link={item.link}
        />
      ))}
    </div>
  )
}
