/* eslint-disable jsx-a11y/alt-text */
import clsx from 'clsx'
import Head from 'next/head'

import styles from './Image.module.scss'

import type { HTMLAttributes } from 'react'

interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  identifier: string | null
  filename?: string
  alt?: string
  width?: string
  height?: string
  srcSet: number[]
  sizes?: string
  responsive?: boolean
  cover?: boolean
  background?: boolean
  lazy?: boolean
  preload?: boolean
}

export const Image = ({
  identifier,
  filename = '',
  srcSet,
  sizes,
  responsive,
  cover,
  background,
  lazy,
  preload,
  className,
  ...rest
}: ImageProps) => {
  if (identifier === null) return null

  const url = `https://cdn.coffeecircle.com/${identifier}/`
  const mediumSize = srcSet[Math.floor(srcSet.length / 2)]
  const imgSrc = src(url, filename, mediumSize)
  const imgSrcSet = buildSrcSet(url, filename, ...srcSet)
  const minXY = Math.min(...srcSet)

  return (
    <>
      {preload && (
        <Head>
          <link key={filename} rel="preload" as="image" href={imgSrc} imageSrcSet={imgSrcSet} imageSizes={sizes} />
        </Head>
      )}
      <img
        className={clsx(
          responsive && styles.responsive,
          cover && styles.cover,
          background && styles.background,
          className,
        )}
        src={imgSrc}
        srcSet={imgSrcSet}
        sizes={sizes}
        height={minXY}
        width={minXY}
        {...(lazy && { loading: 'lazy' })}
        {...(preload && { fetchpriority: 'high' })}
        {...rest}
      />
    </>
  )
}

const src = (url: string, filename: string, width: number) => {
  const resize = `-/resize/${width}x/`
  const quality = '-/quality/lighter/'
  const progressive = '-/progressive/yes/'
  const format = '-/format/auto/'
  return `${url}${resize}${quality}${progressive}${format}${filename}`
}

// eslint-disable-next-line arrow-body-style
const buildSrcSet = (url: string, filename: string, ...widths: number[]) => {
  return widths.map((w) => `${src(url, filename, w)} ${w}w`).join(', ')
}
