import { useTranslation } from 'react-i18next'

import { Button } from 'src/components/Button/Button'
import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { Image } from 'src/components/Image/Image'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'
import { HERO_BANNER } from 'src/selectors'

import styles from './HeroBanner.module.scss'

export const HeroBanner = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.banner} data-cy={HERO_BANNER}>
      <Image
        identifier={t('heroBanner.imageId')}
        filename="hero-banner.jpg"
        alt={t('heroBanner.title')}
        srcSet={[600, 1200, 2000]}
        sizes={`(min-width: ${config.breakpoints.lg}px) 100vw, 200px`} // low resolution image on mobile for SEO
        width="1481"
        height="680"
        cover
        background
        preload
      />
      <Container className={styles.container}>
        <Headline variant="h1" className={styles.text}>
          {t('heroBanner.title')}
        </Headline>
        <Text variant="p" className={styles.text}>
          {t('heroBanner.description')}
        </Text>
        <Button variant="primary" href={t('heroBanner.primaryUrl')} className={styles.button}>
          {t('heroBanner.primaryText')}
        </Button>
        <Button variant="secondary" inverted href={t('heroBanner.secondaryUrl')} className={styles.button}>
          {t('heroBanner.secondaryText')}
        </Button>
      </Container>
    </section>
  )
}
