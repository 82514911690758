import clsx from 'clsx'

import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { ILLUSTRATION_CARDS } from 'src/selectors'

import { IllustrationCard } from './IllustrationCard'
import styles from './IllustrationCards.module.scss'

import type { IllustrationCardProps } from './IllustrationCard'

interface IllustrationCardsProps {
  cards: IllustrationCardProps[]
  bannerHeadLineText: string
  styledCards?: boolean
}

export const IllustrationCards = ({ cards, bannerHeadLineText, styledCards = false }: IllustrationCardsProps) => (
  <Container className={styles.mainContainer} data-cy={ILLUSTRATION_CARDS}>
    <Headline variant="h2" className={styles.headlineWrapper}>
      {bannerHeadLineText}
    </Headline>
    <section className={clsx(styles.cardsContainer, styledCards && styles.linksContainer)}>
      {cards.map(({ name, headlineText, text, href }) => (
        <IllustrationCard
          name={name}
          headlineText={headlineText}
          text={text}
          href={href}
          key={name}
          styledCard={styledCards}
        />
      ))}
    </section>
  </Container>
)
