import dynamic from 'next/dynamic'
import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Newsletter } from 'src/cms/Newsletter/Newsletter'
import { BrandValues } from 'src/components/BrandValues/BrandValues'
import { HeroBanner } from 'src/components/HeroBanner/HeroBanner'
import { BenefitCards } from 'src/components/IllustrationCards/BenefitCards'
import { LinkCards } from 'src/components/IllustrationCards/LinkCards'
import { ImageTextContainer } from 'src/components/ImageTextContainer/ImageTextContainer'
import { PromoCards } from 'src/components/PromoCards/PromoCards'
import { HeadCanonicals } from 'src/components/_nextjs/HeadCanonicals'
import { config } from 'src/config'
import { graphQLClient } from 'src/data/graphql/graphql-client'
import { MultipleProductsForProductCardByIdDocument } from 'src/data/graphql-generated'
import { fetchHomepageContentQuery } from 'src/data/providers/fetchHomepageContent'
import { fetchProductIds } from 'src/data/providers/fetchProductIds'
import { Language } from 'src/enum/Language'
import { getHomepage } from 'src/functions/util/dataLayer'
import { useCartQuery } from 'src/hooks/useCartQuery'
import { HOMEPAGE } from 'src/selectors'

import type { GetStaticPaths, GetStaticProps } from 'next'
import type { ComponentType } from 'react'
import type { HomepageEntrypointsProps } from 'src/components/HomepageEntrypoints/HomepageEntrypoints'
import type { MultipleProductsForProductCardByIdQuery, Product } from 'src/data/graphql-generated'
import type { EnrichedHomepageContentResponse } from 'src/types/HomepageContent'

const HomepageEntrypoints: ComponentType<HomepageEntrypointsProps> = dynamic(() =>
  import('src/components/HomepageEntrypoints/HomepageEntrypoints').then((mod) => mod.HomepageEntrypoints),
)

interface HomeProps {
  entrypointsData: EnrichedHomepageContentResponse
}

export const Home = ({ entrypointsData }: HomeProps) => {
  const {
    isFetched: cartIsFetched,
    computed: { isLoggedIn },
  } = useCartQuery()
  const { t } = useTranslation()

  useEffect(() => {
    if (cartIsFetched) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(getHomepage(isLoggedIn))
    }
  }, [cartIsFetched])

  return (
    <>
      <Head>
        <title>{t('meta.home.title')}</title>
        <meta name="description" content={t('meta.home.description')} />
      </Head>
      <HeadCanonicals de="/" en="/en" />
      <NextSeo
        openGraph={{
          type: 'website',
          title: t('meta.home.title'),
          description: t('meta.home.description'),
          site_name: config.nextSeo.openGraph.site_name,
          images: config.nextSeo.openGraph.images,
        }}
      />

      <div data-cy={HOMEPAGE}>
        <HeroBanner />
        <PromoCards />
        <BenefitCards />
        <HomepageEntrypoints entrypointsData={entrypointsData} />
        <ImageTextContainer
          title={t('doGoodBanner.title')}
          intro={t('doGoodBanner.intro')}
          buttonText={t('doGoodBanner.buttonText')}
          buttonUrl={t('doGoodBanner.buttonUrl')}
          imageId={t('doGoodBanner.imageId')}
        />
        <LinkCards />
        <BrandValues />
        <Newsletter newsletterType="default" />
      </div>
    </>
  )
}

const getStaticProps: GetStaticProps = async ({ params = { lang: Language.de } }) => {
  if (!params) return { props: {} }

  const lang = params.lang as Language

  const contentfulData = await fetchHomepageContentQuery(lang)

  const {
    homePageEntryRows: {
      coffeeProductsCarousel: { commaSeparatedSkuValues: coffeeSkus },
      gearProductsCarousel: { commaSeparatedSkuValues: gearSkus },
    },
  } = contentfulData

  const coffeeSkuArr = coffeeSkus.split(',').map((sku) => sku.trim())
  const coffeeProductsIds = (await fetchProductIds(coffeeSkuArr)).map((coffeeProduct) => coffeeProduct.productId)

  const { nodes: coffeeProductCards }: MultipleProductsForProductCardByIdQuery = await graphQLClient.request({
    document: MultipleProductsForProductCardByIdDocument,
    variables: { ids: coffeeProductsIds, lang: lang.toUpperCase() },
  })

  const gearSkuArr = gearSkus.split(',').map((sku) => sku.trim())
  const gearProductsIds = (await fetchProductIds(gearSkuArr)).map((gearProduct) => gearProduct.productId)

  const { nodes: gearProductCards }: MultipleProductsForProductCardByIdQuery = await graphQLClient.request({
    document: MultipleProductsForProductCardByIdDocument,
    variables: { ids: gearProductsIds, lang: lang.toUpperCase() },
  })

  const entrypointsData: EnrichedHomepageContentResponse = {
    homePageEntryRows: {
      ...contentfulData.homePageEntryRows,
      coffeeProductsCarousel: {
        ...contentfulData.homePageEntryRows.coffeeProductsCarousel,
        products: coffeeProductCards as Product[],
        skus: coffeeSkuArr,
      },
      gearProductsCarousel: {
        ...contentfulData.homePageEntryRows.gearProductsCarousel,
        products: gearProductCards as Product[],
        skus: gearSkuArr,
      },
    },
  }

  return { props: { entrypointsData } }
}

const getStaticPaths: GetStaticPaths = async () => {
  const germanPath = {
    params: {
      lang: Language.de,
      id: 'home',
    },
  }

  const englishPath = {
    params: {
      ...germanPath.params,
      lang: Language.en,
    },
  }

  return {
    paths: [germanPath, englishPath],
    fallback: 'blocking',
  }
}

export { getStaticProps, getStaticPaths }

export default Home
