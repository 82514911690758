import clsx from 'clsx'

import { Headline } from 'src/components/Headline/Headline'
import { Text } from 'src/components/Text/Text'

import styles from './IllustrationCard.module.scss'

export interface IllustrationCardProps {
  name:
    | 'community'
    | 'contact'
    | 'espresso-grinder'
    | 'espresso-machine'
    | 'filter-grinder'
    | 'filter-coffee'
    | 'fully-automatic'
    | 'hand-grinder'
    | 'home'
    | 'mugs'
    | 'preparation'
    | 'roastery'
    | 'subscription'
    | 'unboxing'
    | 'roastery-new'
    | 'subscription-new'
    | 'preparation-new'
  headlineText: string
  text?: string
  href?: string
  styledCard?: boolean
}

export const IllustrationCard = ({ name, headlineText, text, href, styledCard, ...rest }: IllustrationCardProps) => {
  const ElementTag = href ? 'a' : 'section'

  return (
    <ElementTag
      {...(href && { href })}
      {...rest}
      className={clsx(styles.cardWrapper, href && styles.hasLink, styledCard && styles.styledCard)}
    >
      <div className={clsx(styles.cardContainer, styledCard && styles.styledCard)}>
        <img
          src={`/assets/svg/illustrations/${name}.svg`}
          alt={name}
          width={styledCard ? 160 : 220}
          height={styledCard ? 131 : 180}
          loading="lazy"
        />
        <Headline variant="h4" className={clsx(styles.cardHeadline, styledCard && styles.styledCard)}>
          {headlineText}
        </Headline>
        {text && <Text variant="p">{text}</Text>}
      </div>
    </ElementTag>
  )
}
