import { IconChevronRight } from '@tabler/icons-react'

import { Headline } from 'src/components/Headline/Headline'
import { Image } from 'src/components/Image/Image'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'

import styles from './PromoCards.module.scss'

export interface PromoCardProps {
  imageId: string
  title: string
  text: string
  link: string
}

export const PromoCard = ({ imageId, title, text, link }: PromoCardProps) => (
  <a className={styles.card} href={link}>
    <Image
      identifier={imageId}
      alt={title}
      srcSet={[600, 800]}
      sizes={`(min-width: ${config.breakpoints.lg}px) 25vw, (min-width: ${config.breakpoints.sm}px) 50vw, 200px`} // low resolution image on mobile for SEO
      width="369"
      height="224"
      background
      cover
    />
    <div className={styles.content}>
      <Headline variant="h3">{title}</Headline>
      <Text variant="p">{text}</Text>
      <IconChevronRight className={styles.icon} />
    </div>
  </a>
)
