import { useTranslation } from 'react-i18next'

import { IllustrationCards } from './IllustrationCards'

import type { IllustrationCardProps } from './IllustrationCard'

export const LinkCards = () => {
  const { t } = useTranslation()

  const bannerHeadLineText = t('linkCards.headline')

  const cards: IllustrationCardProps[] = [
    {
      name: 'espresso-machine',
      headlineText: t('linkCards.card01.headline'),
      href: t('linkCards.card01.link'),
    },
    {
      name: 'filter-grinder',
      headlineText: t('linkCards.card02.headline'),
      href: t('linkCards.card02.link'),
    },
    {
      name: 'hand-grinder',
      headlineText: t('linkCards.card03.headline'),
      href: t('linkCards.card03.link'),
    },
    {
      name: 'filter-coffee',
      headlineText: t('linkCards.card04.headline'),
      href: t('linkCards.card04.link'),
    },
    {
      name: 'espresso-grinder',
      headlineText: t('linkCards.card05.headline'),
      href: t('linkCards.card05.link'),
    },
  ]

  return <IllustrationCards cards={cards} bannerHeadLineText={bannerHeadLineText} styledCards />
}
